import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "@firebolt-dev/jsx/jsx-runtime";
import {cloneElement} from 'react';
import {useMDXComponents} from 'firebolt';
const MDXLayout = function MDXWrapper({children}) {
  const components = useMDXComponents();
  return cloneElement(children, {
    components
  });
};
function _createMdxContent(props) {
  const _components = {
    code: "code",
    h1: "h1",
    h2: "h2",
    p: "p",
    pre: "pre",
    span: "span",
    table: "table",
    tbody: "tbody",
    td: "td",
    th: "th",
    thead: "thead",
    tr: "tr",
    ...props.components
  }, {Meta} = _components;
  if (!Meta) _missingMdxReference("Meta", true);
  return _jsxs(_Fragment, {
    children: [_jsx(Meta, {
      title: "Package: @firebolt-dev/cors",
      description: "A Firebolt plugin for implementing CORS."
    }), "\n", _jsx(_components.h1, {
      children: "@firebolt-dev/cors"
    }), "\n", _jsxs(_components.p, {
      children: ["The ", _jsx(_components.code, {
        children: "@firebolt-dev/cors"
      }), " package is a plugin for implementing CORS and allowing requests from external origins."]
    }), "\n", _jsx(_components.pre, {
      className: "shiki shiki-themes github-light github-dark",
      style: {
        "--shiki-light": "#24292e",
        "--shiki-dark": "#e1e4e8",
        "--shiki-light-bg": "#fff",
        "--shiki-dark-bg": "#24292e"
      },
      tabIndex: "0",
      title: "firebolt.config.js",
      lineNumbers: true,
      children: _jsxs(_components.code, {
        children: [_jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "import"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " cors "
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "from"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#032F62",
              "--shiki-dark": "#9ECBFF"
            },
            children: " '@firebolt-dev/cors'"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line"
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: "export"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " const"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#005CC5",
              "--shiki-dark": "#79B8FF"
            },
            children: " config"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#D73A49",
              "--shiki-dark": "#F97583"
            },
            children: " ="
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: " {"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "  plugins: ["
          })
        }), "\n", _jsxs(_components.span, {
          className: "line",
          children: [_jsx(_components.span, {
            style: {
              "--shiki-light": "#6F42C1",
              "--shiki-dark": "#B392F0"
            },
            children: "    cors"
          }), _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "({"
          })]
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#6A737D",
              "--shiki-dark": "#6A737D"
            },
            children: "      /* options */"
          })
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "    }),"
          })
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "  ],"
          })
        }), "\n", _jsx(_components.span, {
          className: "line",
          children: _jsx(_components.span, {
            style: {
              "--shiki-light": "#24292E",
              "--shiki-dark": "#E1E4E8"
            },
            children: "}"
          })
        }), "\n", _jsx(_components.span, {
          className: "line"
        })]
      })
    }), "\n", _jsx(_components.p, {
      children: "By default (with no options) all external requests are allowed."
    }), "\n", _jsx(_components.h2, {
      children: "Options"
    }), "\n", _jsxs(_components.table, {
      children: [_jsx(_components.thead, {
        children: _jsxs(_components.tr, {
          children: [_jsx(_components.th, {
            children: "Property"
          }), _jsx(_components.th, {
            children: "Default"
          }), _jsx(_components.th, {
            children: "Type"
          }), _jsx(_components.th, {
            children: "Description"
          })]
        })
      }), _jsxs(_components.tbody, {
        children: [_jsxs(_components.tr, {
          children: [_jsx(_components.td, {
            children: "origin"
          }), _jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "'*'"
            })
          }), _jsxs(_components.td, {
            children: [_jsx(_components.code, {
              children: "String"
            }), " | ", _jsx(_components.code, {
              children: "String[]"
            }), " | ", _jsx(_components.code, {
              children: "(origin:String) => String"
            })]
          }), _jsx(_components.td, {
            children: "The value of \"Access-Control-Allow-Origin\" CORS header."
          })]
        }), _jsxs(_components.tr, {
          children: [_jsx(_components.td, {
            children: "allowMethods"
          }), _jsx(_components.td, {
            children: "All"
          }), _jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "String[]"
            })
          }), _jsx(_components.td, {
            children: "The value of \"Access-Control-Allow-Methods\" CORS header."
          })]
        }), _jsxs(_components.tr, {
          children: [_jsx(_components.td, {
            children: "allowHeaders"
          }), _jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "[]"
            })
          }), _jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "String[]"
            })
          }), _jsx(_components.td, {
            children: "The value of \"Access-Control-Allow-Headers\" CORS header."
          })]
        }), _jsxs(_components.tr, {
          children: [_jsx(_components.td, {
            children: "maxAge"
          }), _jsx(_components.td, {}), _jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "Number"
            })
          }), _jsx(_components.td, {
            children: "The value of \"Access-Control-Max-Age\" CORS header."
          })]
        }), _jsxs(_components.tr, {
          children: [_jsx(_components.td, {
            children: "credentials"
          }), _jsx(_components.td, {}), _jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "Boolean"
            })
          }), _jsx(_components.td, {
            children: "The value of \"Access-Control-Allow-Credentials\" CORS header."
          })]
        }), _jsxs(_components.tr, {
          children: [_jsx(_components.td, {
            children: "exposeHeaders"
          }), _jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "[]"
            })
          }), _jsx(_components.td, {
            children: _jsx(_components.code, {
              children: "String[]"
            })
          }), _jsx(_components.td, {
            children: "The value of \"Access-Control-Expose-Headers\" CORS header."
          })]
        })]
      })]
    })]
  });
}
export default function MDXContent(props = {}) {
  return _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  });
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
